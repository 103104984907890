.App {
  background-color: #167096;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;}


.App-header {
  min-height: 20vh;
  font-size: calc(10px + 2vmin);
}

.App-footer {
  min-height: 20vh;

  flex:auto;
  font-size: calc(10px + 0.5vmin);
}

.App-link {
  color: lightcyan;
}
